import React from 'react'
import Topnavigationbar from '../components/Topnavigationbar'
import society from '../images/society.jpg'
import new_year_celebration1 from '../images/New Year Celebration/1.jpg'
import new_year_celebration2 from '../images/New Year Celebration/2.jpg'
import new_year_celebration3 from '../images/New Year Celebration/3.jpg'
import new_year_celebration4 from '../images/New Year Celebration/4.jpg'
import lalith from '../images/society/lalith.jpeg'
import deeptha from '../images/society/deepththa.jpeg'
import lucky from '../images/society/lucky.jpeg'
import ranjith from '../images/society/ranjith.jpeg'
import harshada_silva_1 from '../images/Harshada Silva/1.jpg'
import harshada_silva_2 from '../images/Harshada Silva/2.jpg'
import harshada_silva_3 from '../images/Harshada Silva/3.jpg'
import harshada_silva_4 from '../images/Harshada Silva/4.jpg'
import Footer from '../components/Footer'

function Society() {
  return (
    <div className="bg-gray-100">
      <Topnavigationbar />

      {/* Society Background Section */}
      <div className="relative h-96">
        <img
          src={society}
          className="w-full h-full object-cover animate-fade-in"
          style={{ objectPosition: 'top' }}
          alt="Society Background"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold animate-fade-in-text">
            Welcome to Welikada Plaza Shop Owners Society
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto mt-8 px-4 py-8 bg-white shadow-lg rounded-lg">

        {/* Society Members Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
          <MemberCard image={ranjith} name="Ranjith Dissanayake" role="President" />
          <MemberCard image={lalith} name="Lalith Priyantha" role="Secretary" />
          <MemberCard image={deeptha} name="Deeptha Wickramasinghe" role="Accountant" />
          <MemberCard image={lucky} name="Lakshman Senevirathne" role="Chief Organizer" />
        </div>

        {/* Additional Society Info */}
        <div className="mt-8 text-gray-800">
          <p className="mb-2">Registration number: Sri Japura/Adroin/07/N.G.O/121</p>
          <p>Account Number: Welikada Plaza Shop Owners Society Commercial Bank</p>
        </div>

        {/* New Year Celebration Section */}
        <div className="mt-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">New Year Celebration</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <img src={new_year_celebration1} alt="New Year Celebration 1" className="rounded-lg shadow-lg" />
            <img src={new_year_celebration2} alt="New Year Celebration 2" className="rounded-lg shadow-lg" />
            <img src={new_year_celebration3} alt="New Year Celebration 3" className="rounded-lg shadow-lg" />
            <img src={new_year_celebration4} alt="New Year Celebration 4" className="rounded-lg shadow-lg" />
          </div>
        </div>

        {/* Harshada Silva Section */}
        <div className="mt-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">ගරු පාර්ලිමේන්තු මන්ත්‍රී හර්ෂද සිල්වා මැතිතුමා වැලිකඩ ප්ලාසා වෙළද සංකීර්ණයේ   අඩුපාඩු සොයා බැලීම සදහා නිරීක්ෂණ චාරිකාවක් සදහා පැමිණීම.</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <img src={harshada_silva_1} alt="Harshada Silva 1" className="rounded-lg shadow-lg" />
            <img src={harshada_silva_2} alt="Harshada Silva 2" className="rounded-lg shadow-lg" />
            <img src={harshada_silva_3} alt="Harshada Silva 3" className="rounded-lg shadow-lg" />
            <img src={harshada_silva_4} alt="Harshada Silva 4" className="rounded-lg shadow-lg" />
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  )
}

// Member Card Component
const MemberCard = ({ image, name, role }) => (
  <div className="flex flex-col items-center bg-white rounded-lg shadow-lg p-4">
    <img src={image} alt={name} className="rounded-full h-32 w-32 object-cover mb-4" />
    <p className="text-lg font-semibold text-gray-800 mb-2">{name}</p>
    <p className="text-base font-medium text-gray-600">{role}</p>
  </div>
);


export default Society