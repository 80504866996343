import React from 'react'
import Topnavigationbar from '../components/Topnavigationbar'
import ground_floor from '../images/Floor Plan/ground floor.jpeg'
import floor_plan_1 from '../images/Floor Plan/1st floor.jpeg'
import floor_plan_2 from '../images/Floor Plan/new floor 2.jpg'
import Footer from '../components/Footer'

function Shops() {
  return (
    <div>
        <Topnavigationbar/>
        <br></br>
        <br></br>
        <br></br>
        <div className="flex-1 px-4">
        {/* Images */}
        <img src={ground_floor} alt="ground floor" className="mb-4 block" />
        <img src={floor_plan_1} alt="floor plan 1" className="mb-4 block" />
        <img src={floor_plan_2} alt="floor plan 2" className="mb-4 block" />
      </div>

      {/* For Rent Section */}
      <div className="lg:flex-1 flex items-center justify-center lg:justify-end lg:pr-4 mt-4 lg:mt-0">
        <div className="relative" style={{marginTop:-50}}>
          <div className="h-2 w-2 bg-red-500 rounded-full inline-block mr-2"></div>
          <p className="text-red-500 text-sm font-semibold inline-block">For Rent</p>
        </div>
      </div>

      
        
      {/* Shop Information Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="border-b-2 border-gray-300 px-4 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Number</th>
              <th className="border-b-2 border-gray-300 px-4 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Square Feet</th>
              <th className="border-b-2 border-gray-300 px-4 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Contact Person</th>
              <th className="border-b-2 border-gray-300 px-4 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Contact Number</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="border-gray-300 px-4 py-2 text-sm font-medium text-gray-900">NO.41</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">-</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">Kusumawathi</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">0773258249</td>
            </tr>
            <tr>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">NO.53</td>
              <td className="border-gray-300 px-4 py-2 text-sm font-medium text-gray-900">257</td>
              <td className="border-gray-300 px-4 py-2 text-sm font-medium text-gray-900">A.s.c. De Silva</td>
              <td className="border-gray-300 px-4 py-2 text-sm font-medium text-gray-900">0759347997</td>
            </tr>
            <tr>
              <td className="border-gray-300 px-4 py-2 text-sm font-medium text-gray-900">NO.60</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">-</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">Ganegama</td>
              <td className="border-gray-300 px-4 py-2 text-sm text-gray-900">0777797694</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Footer/>
    </div>
  )
}

export default Shops