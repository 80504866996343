import React from 'react'
import Topnavigationbar from '../components/Topnavigationbar'
import {motion} from 'framer-motion';
import Footer from '../components/Footer';

function Aboutus() {
  return (
    <div>
      <Topnavigationbar />
      <br></br>
      <br></br>
      <br></br>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <header className="text-center mb-8">
          <p className="text-gray-600">Welcome to our story...</p>
        </header>

        <motion.section
          className="animate-fadeIn mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-semibold mb-2 text-gray-900">Our Mission</h2>
          <p className="text-gray-700 leading-relaxed">
            At Welikada Plaza, our mission is to create a vibrant community hub where shopping, entertainment, and dining come together in harmony. We aim to provide an exceptional experience for every visitor, offering diverse retail options, exciting events, and memorable moments that enrich lives and foster connections.
          </p>
        </motion.section>

        <motion.section
          className="animate-fadeIn mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-2xl font-semibold mb-2 text-gray-900">Our History</h2>
          <p className="text-gray-700 leading-relaxed">
            Founded with a vision in 1996, Welikada Plaza has grown into a cornerstone of Colombo's cultural and commercial landscape. From our humble beginnings, we've expanded to become a premier destination for locals and tourists alike, continually evolving to meet the dynamic needs of our diverse clientele.
          </p>
        </motion.section>

        <motion.section
          className="animate-fadeIn mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2 className="text-2xl font-semibold mb-2 text-gray-900">Our Values</h2>
          <ul className="list-disc list-inside text-gray-700">
            <li className="mb-2">Community: We prioritize creating a welcoming environment that fosters community spirit and inclusivity.</li>
            <li className="mb-2">Excellence: We strive for excellence in everything we do, from customer service to facility management.</li>
            <li className="mb-2">Innovation: We embrace innovation to continuously enhance the shopping and visitor experience.</li>
            <li className="mb-2">Sustainability: We are committed to sustainable practices that minimize our environmental footprint.</li>
          </ul>
        </motion.section>

        <motion.section
          className="animate-fadeIn mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h2 className="text-2xl font-semibold mb-2 text-gray-900">Explore Welikada Plaza</h2>
          <p className="text-gray-700 leading-relaxed">
            Whether you're here to shop, dine, or simply unwind, we invite you to explore all that Welikada Plaza has to offer. Plan your visit today and experience the excitement!
          </p>
        </motion.section>
      </div>
      <Footer/>
    </div>
  )
}

export default Aboutus