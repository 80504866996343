import React from 'react'
import Topnavigationbar from '../components/Topnavigationbar'
import Footer from '../components/Footer'

function Gallery() {
  return (
    <div>
        <Topnavigationbar/>

       
    </div>
  )
}

export default Gallery